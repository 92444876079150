import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import DailyRaceForm from "./25kDailyRaceForm";
const DailyRaceSetting = () => {
  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { role } = loginAdmin || {};
  return (
    <Fragment>
      <Breadcrumb title="25K Daily Race Setting" parent="25K Daily Race" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>25K Daily Race Setting</h5>
              </CardHeader>
              <CardBody>
                <DailyRaceForm role={role} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DailyRaceSetting;
