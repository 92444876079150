import React, { Fragment, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Chart } from "react-google-charts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { adminInstance } from "../../config/axios";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Label,
} from "reactstrap";
import { filterDateObj } from "../../utils/multiuseFunctions";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const AffiliateStats = () => {
  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { role } = loginAdmin || [];
  let readRole = role?.some((r) =>
    ["suUsers", "superAdmin", "readDashboard"].includes(r)
  );

  const initialState = {
    type: "last7day",
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
  };
  const [payload, setPayload] = useState(initialState);
  const initialData = {
    deposit: "",
    totalDeposit: "",
    dates: "",
    totalunboxed: "",
    unboxed: "",
    shiprequests: "",
    totalShip: "",
    netProfit: "",
    pnlData: "",
  };
  const [data, setData] = useState(initialData);

  const fillterArray = [
    { today: "Today" },
    { yesterday: "Yesterday" },
    { last7day: "Last 7 days" },
    { last28day: "last  28 days" },
    { last90day: "Last 90 days" },
    { yeartodate: "Year to date" },
    { monthtodate: "Month to date" },
    { weektodate: "Week to date" },
    { custom: "custom" },
  ];

  const handleDateChange = (name) => (val) => {
    const copy = { ...payload };
    copy[name] = val;
    if (copy.rangeStartDate < copy.rangeEndDate) {
      copy["type"] = "custom";
    }
    setPayload(copy);
  };

  const getStatsDataforAffiliate = async () => {
    try {
      const filterData = filterDateObj(payload);
      const response = await adminInstance().post(
        "api/admin//statsSummaryforAffiliate",
        filterData
      );
      const {
        total24,
        totalReferalCount,
        total25,
        totalReferalBy,
        total26,
        totalReferalCommission,
        total27,
        totalReferalBonus,
        avgOrderArray,
      } = response.data;

      setData({
        total24,
        totalReferalCount,
        total25,
        totalReferalBy,
        total26,
        totalReferalCommission,
        total27,
        totalReferalBonus,
        avgOrderArray,
      });
    } catch (error) {}
  };
  useEffect(() => {
    getStatsDataforAffiliate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);
  const LineOptions = {
    hAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
      viewWindow: {
        min: -0.1,
      },
    },
    vAxis: {
      textPosition: "none",
      baselineColor: "transparent",
      gridlineColor: "transparent",
      viewWindow: {
        min: -0.1,
      },
    },
    colors: ["#03D1FF"],
    legend: "none",
  };

  const [showCustom, setShowCustom] = useState(false);

  const handleDateFilter = (val, data) => {
    if (val === "custom") {
      console.log(val);
      setShowCustom(true);
    } else {
      setShowCustom(false);
    }
    setPayload({ ...payload, type: val });
  };
  const [dataTable, setDataTable] = useState();
  const getStatsSummaryTableforAffiliate = async () => {
    const filterData = filterDateObj(payload);
    const response = await adminInstance().post(
      "api/admin/statsSummaryTableforAffiliate",
      filterData
    );
    const { topReferrers, topEarners } = response?.data;

    setDataTable({
      topReferrers,
      topEarners,
    });
  };
  useEffect(() => {
    getStatsSummaryTableforAffiliate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <Fragment>
      <Breadcrumb title="Affiliate Stats" parent="Affiliate" />
      {readRole ? (
        <Container className="dashboard-page" fluid={true}>
          <Row>
            <Col xl="12 xl-50" md="12">
              <select
                class="category-search"
                name="datetype"
                value={payload.type}
                onChange={(e) => handleDateFilter(e.target.value, "")}
              >
                <option value="today"> Today </option>
                <option value="yesterday"> Yesterday </option>
                <option value="last7day">Last 7 days </option>
                <option value="last28day"> last 28 days </option>
                <option value="last90day"> Last 90 days </option>
                <option value="weektodate"> Week to date </option>
                <option value="monthtodate"> Month to date </option>
                <option value="yeartodate"> Year to date </option>
                <option value="custom"> Custom </option>
              </select>
              {showCustom && (
                <div className="custom-date">
                  <div className="custom-date-content">
                    <Label>Start Date</Label>
                    <DatePicker
                      selected={payload.rangeStartDate}
                      onChange={handleDateChange("rangeStartDate")}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="custom-date-content">
                    <Label>End Date</Label>
                    <DatePicker
                      selected={payload.rangeEndDate}
                      maxDate={new Date()}
                      onChange={handleDateChange("rangeEndDate")}
                    />
                  </div>
                </div>
              )}
            </Col>
            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL REFERAL COUNT</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.total24?.length > 0
                                ? data.total24
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {(data?.totalReferalCount
                            ? data.totalReferalCount
                            : 0) || 0}{" "}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        REFERAL COUNT{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      {/* <p>Amount of money that users have deposited</p> */}
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {(data?.totalReferalCount
                          ? data.totalReferalCount
                          : 0) || 0}{" "}
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL REFERAL BY</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.total25?.length > 0
                                ? data.total25
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {(data?.totalReferalBy ? data.totalReferalBy : 0) ||
                            0}{" "}
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        REFERAL By{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      {/* <p>Amount of money that users have deposited</p> */}
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {(data?.totalReferalBy ? data.totalReferalBy : 0) || 0}{" "}
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL REFERAL COMMISSION</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.total26?.length > 0
                                ? data.total26
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {(data?.totalReferalCommission
                            ? data.totalReferalCommission.toFixed(2)
                            : 0) || 0}{" "}
                          $
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        REFERAL COMMISSION{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      {/* <p>Amount of money that users have deposited</p> */}
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {(data?.totalReferalCommission
                          ? data.totalReferalCommission.toFixed(2)
                          : 0) || 0}{" "}
                        $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL REFERAL BONUS</h6>
                  <Row>
                    <Col className="col-6">
                      <div className="small-chartjs">
                        <div
                          className="flot-chart-placeholder"
                          id="simple-line-chart-sparkline-3"
                        >
                          <Chart
                            height={"60px"}
                            chartType="LineChart"
                            loader={<div>Loading Chart</div>}
                            data={
                              data?.total27?.length > 0
                                ? data.total27
                                : [
                                    ["x", "$"],
                                    [0, 0],
                                  ]
                            }
                            options={LineOptions}
                            legend_toggle
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="col-6">
                      <div className="value-graph">
                        <h3>
                          {(data?.totalReferalBonus
                            ? data.totalReferalBonus.toFixed(2)
                            : 0) || 0}{" "}
                          $
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                      <p>
                        REFERAL COUNT{" "}
                        {
                          fillterArray.filter((el) => el[payload?.type])[0][
                            payload?.type
                          ]
                        }
                      </p>
                      {/* <p>Amount of money that users have deposited</p> */}
                      <h5 className="mb-0" style={{ color: "#000 " }}>
                        {(data?.totalReferalBonus
                          ? data.totalReferalBonus.toFixed(2)
                          : 0) || 0}{" "}
                        $
                      </h5>
                      <p>{data?.dates}</p>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>TOP REFERRERS </h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    <table className="table table-bordernone mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Referal Count</th>
                          <th scope="col">Level Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataTable?.topReferrers?.length > 0 &&
                          dataTable.topReferrers.map((el) => (
                            <tr>
                              <td className="font-primary">{el?.username}</td>
                              <td className="digits">{el?.referalCount}</td>
                              <td className="digits">
                                {el?.affiliateLevel?.levelName}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="6 xl-100">
              <Card className="height-equal">
                <CardHeader>
                  <h5>TOP EARNERS </h5>
                </CardHeader>
                <CardBody>
                  <div className="user-status table-responsive products-table">
                    <table className="table table-bordernone mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Total Earning</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataTable?.topEarners?.length > 0 &&
                          dataTable.topEarners.map((el) => (
                            <tr>
                              <td className="font-primary">{el?.username}</td>
                              <td className="digits">
                                {el?.referalCommission + " "}$
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <h2>Welcome to admin Klash.gg</h2>
      )}
    </Fragment>
  );
};

export default AffiliateStats;
