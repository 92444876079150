/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
// import { Link } from 'react-router-dom'
//import data from "../../assets/data/listVendor";
//import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
// import { useSelector, useDispatch } from 'react-redux'
// import { adminInstance } from '../../config/axios'
// import { getAllRarityList } from '../../../reducers/adminReducers'
import ListDatatable from "./listdatatable";
import ConfirmationPopUp from "../common/ConfirmationPopUp";
// import { toast } from 'react-toastify'
// import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { ccpaymenttransactions } from "../../reducers/adminReducers";
import Loader from "../loader/loader";

const FundsManagement = () => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const { ccpaymenttransactionsList } = result || {};
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState();
  // const [dummy, setDummy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    transactionId: "",
    approvedStatus: "",
    amount: 0,
    userId: "",
  });

  const getCCpaymentTransactionDetails = async () => {
    setLoading(true);
    const response = await dispatch(ccpaymenttransactions("Withdrawal"));
    if (response?.payload) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCCpaymentTransactionDetails();
  }, []);

  // const getAllRarityData = async () => {
  //   setLoading(true);
  //   const respnse = await dispatch(getWithdrawalRequests());
  //   // console.log('This is boat play ', respnse?.payload)
  //   if (respnse?.payload) {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   getAllRarityData();
  // }, [dummy]);
  // const handleConfirm = async (id) => {
  //   setDummy(false);
  //   try {
  //     // console.log('payload====>>', payload);
  //     setOpen(false);
  //     setDummy(true);
  //     dispatch(getWithdrawalRequests());
  //   } catch (error) {}
  // };
  // const onCloseModal = () => {
  //   setOpen(false);
  // };

  return (
    <Fragment>
      <Breadcrumb title="Withdraw Funds List" parent="Withdraw" />
      <Container fluid={true}>
        <div className="admin-card">
          <Card>
            <CardHeader>
              <h5>Withdraw Details</h5>
            </CardHeader>
            <CardBody>
              <div className="btn-popup pull-right">
                {/* <Link to='/products/add-raritylevel' className='btn btn-secondary'>
                Withdraw Funds
              </Link> */}
              </div>
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete filter__input"
              >
                {!loading ? (
                  ccpaymenttransactionsList?.data?.length > 0 && (
                    <ListDatatable
                      multiSelectOption={true}
                      myData={ccpaymenttransactionsList}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                      setActiveId={setActiveId}
                      setOpen={setOpen}
                      setPayload={setPayload}
                    />
                  )
                ) : (
                  <Loader />
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>

      <ConfirmationPopUp
        title={`Are You Sure Want to ${
          payload?.approvedStatus === "declined" ? "Decline" : "Approve"
        } `}
        subtitle=""
        open={open}
        // handleConfirm={handleConfirm}
        // onCloseModal={onCloseModal}
        id={activeId}
      />
    </Fragment>
  );
};
export default FundsManagement;
