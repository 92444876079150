import React, { Fragment, useEffect, useState } from "react";
import { Tabs, TabPanel } from "react-tabs"; // TabList,  Tab
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "../../assets/styles/admin.css";
import { useDispatch } from "react-redux";
import { createRackBackRain } from "../../reducers/adminReducers";
import { toast } from "react-toastify";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { adminInstance } from "../../config/axios";

const AddRainForm = () => {
  const initialData = {
    rainName: "",
    rainTime: "",
    runsPerDay: "",
    startDate: "",
    endDate: "",
    currency: "",
  };
  const [rainData, setRainData] = useState(initialData);
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const history = useHistory();
  const handleRainChnage = (e) => {
    const { name, value } = e.target;
    setRainData({ ...rainData, [name]: value });
    setError({ ...error, [name]: "" });
  };

  const handleRainStartDateChnage = (date) => {
    if (date) {
      setRainData({ ...rainData, startDate: date });
      setError({ ...error, startDate: "" });
    }
  };

  const handleRainEndDateChnage = (date) => {
    if (date) {
      setRainData({ ...rainData, endDate: date });
      setError({ ...error, endDate: "" });
    }
  };

  const validateForm = () => {
    let formError = {};
    if (!rainData?.rainName) {
      formError.rainName = "Rain Name is required";
    }
    if (!rainData?.rainTime) {
      formError.rainTime = "Rain Time is required";
    }
    if (!rainData.runsPerDay || isNaN(rainData.runsPerDay)) {
      formError.runsPerDay = "Runs per day must be a valid number.";
    }
    if (!rainData.startDate) {
      formError.startDate = "Start date is required.";
    }
    if (!rainData.endDate) {
      formError.endDate = "End date is required.";
    } else if (rainData.startDate && rainData.endDate < rainData.startDate) {
      formError.endDate = "End date cannot be before start date.";
    }
    if (!rainData.currency) {
      formError.currency = "Currency selection is required.";
    }
    return formError;
  };

  console.log("rainData", rainData);

  const formatToLocalISOString = (date) => {
    if (!(date instanceof Date)) return "";
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60000);
    return localDate.toISOString().slice(0, 19);
  };

  const handleSubmitRainDate = async (e) => {
    e.preventDefault();
    const formError = validateForm();

    if (Object.keys(formError).length === 0) {
      const payload = {
        ...rainData,
        startDate: formatToLocalISOString(rainData.startDate),
        endDate: formatToLocalISOString(rainData.endDate),
      };

      if (categoryId) {
        const response = await adminInstance().put(
          `/api/admin/updateRackBackRain/${categoryId}`,
          payload
        );
        const { code, msg } = response.data;
        if (code === 200) {
          toast.success(msg);
          history.push("/schedule-rain");
        } else {
          toast.error(msg);
        }
      } else {
        dispatch(createRackBackRain(payload))
          .then((res) => {
            const { code, msg } = res?.payload?.resultData?.data;
            if (code === 200) {
              toast.success(msg);
              history.push("/schedule-rain");
            } else {
              toast.error(msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setError(formError);
    }
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let categoryId = query.get("id");

  const getSingleCategoryData = async (id) => {
    try {
      const response = await adminInstance().get(
        `api/admin/getRackBackRainDataById/${id}`
      );
      const { code, rainSingleData } = response.data;
      if (code === 200) {
        const dataObj = {
          rainName: rainSingleData?.name || "",
          rainTime: rainSingleData?.cronTiming || "",
          runsPerDay: rainSingleData?.runsPerDay || "",
          startDate:
            (rainSingleData?.startDate &&
              new Date(rainSingleData?.startDate)) ||
            "",
          currency: rainSingleData?.currency,
          endDate:
            (rainSingleData?.endDate && new Date(rainSingleData?.endDate)) ||
            "",
        };
        setRainData(dataObj);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (categoryId) {
      getSingleCategoryData(categoryId);
    }
  }, [categoryId]);
  return (
    <Fragment>
      <Tabs>
        <TabPanel>
          <Form
            className="needs-validation user-add admin-type"
            onSubmit={handleSubmitRainDate}>
            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span>*</span> Rain Name
                </Label>
                <input
                  className="form-control"
                  type="text"
                  name="rainName"
                  value={rainData?.rainName}
                  onChange={handleRainChnage}
                />
                {error?.rainName && (
                  <p className="text-danger">{error?.rainName}</p>
                )}
              </div>
            </FormGroup>
            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span>*</span> Rain Time Interval
                </Label>
                <input
                  className="form-control"
                  type="text"
                  name="rainTime"
                  value={rainData?.rainTime}
                  onChange={handleRainChnage}
                />
                {error?.rainTime && (
                  <p className="text-danger">{error?.rainTime}</p>
                )}
              </div>
            </FormGroup>
            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span>*</span> Runs Per Day
                </Label>
                <input
                  className="form-control"
                  type="text"
                  name="runsPerDay"
                  value={rainData?.runsPerDay}
                  onChange={handleRainChnage}
                />
                {error?.runsPerDay && (
                  <p className="text-danger">{error?.runsPerDay}</p>
                )}
              </div>
            </FormGroup>

            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span>*</span> Select Rain Currency
                </Label>
                <Input
                  type="select"
                  name="currency"
                  value={rainData.currency}
                  onChange={handleRainChnage}>
                  <option value="">Select Currency</option>
                  <option value="Coins">Coin</option>
                  <option value="USD">USD</option>
                </Input>
                {error.currency && (
                  <p className="text-danger">{error?.currency}</p>
                )}
              </div>
            </FormGroup>

            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input rain-datepicker col-xl-5 col-md-7 d-flex flex-column">
                <Label className="col-form-label pt-0">
                  <span>*</span> Start Date
                </Label>
                <DatePicker
                  selected={rainData?.startDate}
                  onChange={handleRainStartDateChnage}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy h:mm aa"
                />
                {error?.startDate && (
                  <p className="text-danger">{error?.startDate}</p>
                )}
              </div>
            </FormGroup>

            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input rain-datepicker col-xl-5 col-md-7 d-flex flex-column">
                <Label className="col-form-label pt-0">
                  <span>*</span> End Date
                </Label>
                <DatePicker
                  selected={rainData?.endDate}
                  onChange={handleRainEndDateChnage}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MM/dd/yyyy h:mm aa"
                />
                {error?.endDate && (
                  <p className="text-danger">{error?.endDate}</p>
                )}
              </div>
            </FormGroup>

            <div className="form-button d-flex justify-content-start pt-3">
              <Button color="primary" type="submit">
                {categoryId ? "Update" : "Add"}
              </Button>
            </div>
          </Form>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};
export default AddRainForm;
