import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import Breadcrumb from "../common/breadcrumb";
import ListDatatable from "./listDataTable";
import { useDispatch, useSelector } from "react-redux";
import { getRackBackRainData } from "../../reducers/adminReducers";
import DeleteConfirmPopup from "../common/deleteConfirmPopup";
import { adminInstance } from "../../config/axios";
import { toast } from "react-toastify";
import Loader from "../loader/loader";
// import Loader from "../loader/loader";
const RainSchedule = () => {
  const result = useSelector((state) => state.admin);
  const { rackBackRainData } = result || {};
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dummy, setDummy] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState();
  const fetchRackBackRainData = () => {
    setLoading(true);
    dispatch(getRackBackRainData()).then((res) => {
      if (res?.payload) {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    fetchRackBackRainData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dummy]);

  const handleOnDelete = async (id) => {
    setDummy(false);
    try {
      setOpen(false);
      const response = await adminInstance().delete(
        `api/admin/deleteRackBackRain/${id}`
      );
      setDummy(true);
      const { code } = response.data;
      if (code === 200) {
        toast.success("Successfully Deleted", { toastId: 1 });
      } else {
        setDummy(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Fragment>
        <Breadcrumb title="Rain Schedule" parent="RakeBack Rain" />
        <Container fluid={true}>
          <div className="admin-card">
            <Card>
              <CardHeader>
                <h5>Rain Schedule </h5>
              </CardHeader>
              <CardBody>
                <div className="btn-popup pull-right">
                  <Link to="/add-rain" className="btn btn-secondary">
                    Create Rain Schedule
                  </Link>
                </div>
                <div className="clearfix"></div>
                <div
                  id="batchDelete"
                  className="category-table user-list order-table coupon-list-delete">
                  {!loading ? (
                    rackBackRainData.length > 0 && (
                      <ListDatatable
                        multiSelectOption={true} 
                        myData={rackBackRainData}
                        pageSize={10}
                        pagination={true}
                        setActiveId={setActiveId}
                        setOpen={setOpen}
                        class="-striped -highlight"
                      />
                    )
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
        <DeleteConfirmPopup
          title={"Delete Category"}
          open={open}
          handleOnDelete={handleOnDelete}
          onCloseModal={onCloseModal}
          id={activeId}
        />
      </Fragment>
    </>
  );
};
export default RainSchedule;
