import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AddRainForm from "./addRainForm";
import Loader from "../loader/loader";
const AddRainSchedule = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <Fragment>
      <Breadcrumb title="addRain" parent="Rain Schedule" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Add Rain Schedule</h5>
              </CardHeader>
              <CardBody>{!loading ? <AddRainForm /> : <Loader />}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddRainSchedule;
