import React, { useEffect, useState } from "react";
import { Button, Label, Form, FormGroup } from "reactstrap";
import {
  updateRackBackSettings,
  getRackBackSettings,
} from "../../reducers/adminReducers";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
const RackBackForm = ({ role }) => {
  const initialObj = {
    dailyRackback: 0,
    weeklyRackback: 0,
    monthlyRackback: 0,
  };
  const [rackBackData, setRackBackData] = useState(initialObj);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const result = useSelector((state) => state.admin);
  const { rackBackSettingsData } = result || {};
  const handleChangeRackBackSettings = (e) => {
    const { name, value } = e.target;
    setRackBackData({ ...rackBackData, [name]: value });
  };

  const handleSubmitRackBackSettings = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(updateRackBackSettings(rackBackData))
      .then((res) => {
        if (res.payload.resultData?.data?.code === 200) {
          toast.success(res.payload.resultData?.data?.message, {
            toastId: "success",
          });
        } else {
          toast.error("Something went wrong", { toastId: "wrong" });
        }
      })
      .catch((err) => {
        toast.error("Something went wrong", { toastId: "wrong" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(getRackBackSettings());
  }, [dispatch]);

  useEffect(() => {
    if (rackBackSettingsData) {
      setRackBackData({
        dailyRackback: rackBackSettingsData?.dailyRackBack || 0,
        weeklyRackback: rackBackSettingsData?.weeklyRackBack || 0,
        monthlyRackback: rackBackSettingsData?.monthlyRackBack || 0,
      });
    }
  }, [rackBackSettingsData]);
  return (
    <>
      <Form
        className="needs-validation user-add"
        onSubmit={handleSubmitRackBackSettings}>
        <FormGroup className="row justify-content-start mx-0">
          <div className="admin-input col-xl-5 col-md-7">
            <Label className="col-form-label pt-0">Daily Rackback</Label>
            <input
              className="form-control"
              placeholder="Daily Rackback Percentage"
              type="number"
              name="dailyRackback"
              value={rackBackData.dailyRackback}
              onChange={handleChangeRackBackSettings}
            />
          </div>
        </FormGroup>

        <FormGroup className="row justify-content-start mx-0">
          <div className="admin-input col-xl-5 col-md-7">
            <Label className="col-form-label pt-0">Weekly Rackback</Label>
            <input
              className="form-control"
              placeholder="Weekly Rackback Percentage"
              type="number"
              name="weeklyRackback"
              value={rackBackData.weeklyRackback}
              onChange={handleChangeRackBackSettings}
            />
          </div>
        </FormGroup>

        <FormGroup className="row justify-content-start mx-0">
          <div className="admin-input col-xl-5 col-md-7">
            <Label className="col-form-label pt-0">Monthly Rackback</Label>
            <input
              className="form-control"
              placeholder="Monthly Rackback Percentage"
              type="number"
              name="monthlyRackback"
              value={rackBackData.monthlyRackback}
              onChange={handleChangeRackBackSettings}
            />
          </div>
        </FormGroup>
        {role?.some((r) =>
          [
            "suUsers",
            "superAdmin",
            "writeQuickSellPercentage",
            "writePreferences",
          ].includes(r)
        ) && (
          <div className="form-button d-flex justify-content-start pt-3">
            <Button disabled={loading} type="submit" color="primary">
              {loading ? "Updating..." : "Update"}
            </Button>
          </div>
        )}
      </Form>
    </>
  );
};

export default RackBackForm;
