import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import DataTableExtensions from "react-data-table-component-extensions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DeleteConfirmForSelected from "../common/deleteConfirmForSelected";
// import { add3Dots } from "../../utils/queryString";
import {
  toastDelPermission,
  toastEditPermission,
} from "../../utils/toastPermission";
import {
  deleteSelectedRackBackRain,
  getRackBackRainData,
} from "../../reducers/adminReducers";
import { toast } from "react-toastify";
const ListDatatable = ({
  id,
  myData,
  myClass,
  multiSelectOption,
  pagination,
  setActiveId,
  setOpen,
}) => {
  console.log("myData", myData);
  const history = useHistory();
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { role } = loginAdmin || [];
  const [data, setData] = useState();
  const [selectedBox, setSelectedBox] = useState([]);
  const [openDelPopup, setOpenDelPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState(true);
  useEffect(() => {
    setData(myData);
  }, [myData]);
  let delRole = role?.some((r) =>
    ["suUsers", "superAdmin", "delCategory"].includes(r)
  );
  let editRole = role?.some((r) =>
    ["suUsers", "superAdmin", "writeCategory"].includes(r)
  );
  const columnDefs = [
    {
      name: "Rain Schedule Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Rain Timming",
      selector: (row) => row.cronTiming || "",
      sortable: true,
    },
    {
      name: "Runs Per Day",
      selector: (row) => row?.runsPerDay || 0,
      sortable: true,
    },
    {
      name: "Rain Currency",
      selector: (row) => row?.currency || 0,
      sortable: true,
    },
    {
      name: "Total Rain Count",
      selector: (row) => row?.currentRunCount || 0,
      sortable: true,
    },
    {
      name: "Strat Date/Time",
      selector: (row) => moment.utc(row.startDate).format("DD/MM/YYYY HH:mm"),
      sortable: true,
    },
    {
      name: "End Date/Time",
      selector: (row) => moment.utc(row.endDate).format("DD/MM/YYYY HH:mm"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.isActive ? "Active" : "Inactive"),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionButton
          row={row}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          role={role}
          delRole={delRole}
          editRole={editRole}
        />
      ),
    },
  ];

  useEffect(() => {
    const check = delRole || editRole;
    if (!check) {
      columnDefs.pop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefs]);
  const handleEdit = (id) => {
    setActiveId(id);
    history.push(`/add-rain?id=${id}`);
    // console.log(id, 'edit');
  };
  const handleDelete = (id) => {
    setActiveId(id);
    setOpen(true);
  };
  const handleSelectCategory = (row) => {
    let selectedPro = [];
    if (!row.allSelected) {
      row?.selectedRows.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedBox(selectedPro);
      setSelectedRows(true);
    }
    if (row.allSelected) {
      row?.selectedRows.forEach((el) => {
        selectedPro?.push(el?._id);
      });
      setSelectedBox(selectedPro);
      setSelectedRows(true);
    }
  };
  const handleSelectedDelete = async (e, id) => {
    e.preventDefault();
    dispatch(deleteSelectedRackBackRain(id))
      .then((response) => {
        if (response?.payload?.resultData?.code === 200) {
          dispatch(getRackBackRainData());
          setOpenDelPopup(false);
          setSelectedRows(false);
          toast.success(response?.payload?.resultData?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };
  const onCloseModal = () => {
    setOpenDelPopup(false);
  };
  const selectDeleteOpen = (e) => {
    e.preventDefault();
    if (selectedBox.length > 0) {
      setOpenDelPopup(true);
    }
  };
  return (
    <div>
      <DeleteConfirmForSelected
        open={openDelPopup}
        handleSelectedDelete={handleSelectedDelete}
        onCloseModal={onCloseModal}
        id={selectedBox}
      />
      {role?.some((r) =>
        ["suUsers", "superAdmin", "delCategory"].includes(r)
      ) && (
        <div className="btn-popup pull-right">
          <span className="btn btn-secondary" onClick={selectDeleteOpen}>
            Delete
          </span>
        </div>
      )}
      <Fragment>
        <DataTableExtensions
          columns={columnDefs}
          data={data}
          print={false}
          export={false}>
          <DataTable
            data={data}
            columns={columnDefs}
            className={myClass}
            pagination
            striped={true}
            center={true}
            selectableRows
            clearSelectedRows={selectedRows}
            onSelectedRowsChange={(e) => handleSelectCategory(e)}
          />
        </DataTableExtensions>
      </Fragment>
    </div>
  );
};

const ActionButton = ({
  row,
  handleDelete,
  handleEdit,
  role,
  delRole,
  editRole,
}) => {
  return (
    <div className="action-btn">
      {delRole && (
        <span>
          <i
            onClick={() =>
              delRole ? handleDelete(row._id) : toastDelPermission("rain")
            }
            className="fa fa-trash"
            disabled="disabled"
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "#e4566e",
            }}></i>
        </span>
      )}
      {/* )} */}

      {editRole && (
        <span>
          <i
            onClick={() =>
              editRole ? handleEdit(row._id) : toastEditPermission("category")
            }
            className="fa fa-pencil"
            disabled
            style={{
              width: 35,
              fontSize: 20,
              padding: 11,
              color: "rgb(40, 167, 69)",
            }}></i>
        </span>
      )}
    </div>
  );
};

export default ListDatatable;
