/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import ConfirmationPopUp from "../common/ConfirmationPopUp";
import { useSelector, useDispatch } from "react-redux";
import { getDepositListbyUser } from "../../reducers/adminReducers";
import Loader from "../loader/loader";
import ListDatatable from "./listdatatable";
const DepositeManagement = () => {
  const dispatch = useDispatch();
  const result = useSelector((state) => state.admin);
  const { ccpaymentDepositList } = result || {};
  const [open, setOpen] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [loading, setLoading] = useState(false);
  const getDepositeData = async () => {
    setLoading(true);
    const response = await dispatch(getDepositListbyUser());
    if (response?.payload) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getDepositeData();
  }, []);

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Breadcrumb title="Deposite Transaction List" parent="Deposite" />
      <Container fluid={true}>
        <div className="admin-card">
          <Card>
            <CardHeader>
              <h5>Deposite</h5>
            </CardHeader>
            <CardBody>
              <div className="btn-popup pull-right"></div>
              <div className="clearfix"></div>
              <div
                id="batchDelete"
                className="category-table user-list order-table coupon-list-delete deposit-table"
              >
                {!loading ? (
                  ccpaymentDepositList.length > 0 && (
                    <ListDatatable
                      multiSelectOption={true}
                      myData={ccpaymentDepositList}
                      pageSize={10}
                      pagination={true}
                      class="-striped -highlight"
                      setActiveId={setActiveId}
                      setOpen={setOpen}
                    />
                  )
                ) : (
                  <Loader />
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Container>
      <ConfirmationPopUp
        open={open}
        onCloseModal={onCloseModal}
        id={activeId}
      />
    </Fragment>
  );
};
export default DepositeManagement;
